/* Navbar styling */
nav {
    background-color: inherit;
    transition: all 0.3s;
}

#websiteTitle-light, 
#websiteTitle-dark {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    transition: all 0.3s;
}

#websiteTitle-light {
    color: #DD58D6;
}

#websiteTitle-dark {
    color: #fff;
}

#themeTogglerButton-light,
#themeTogglerButton-dark {
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 2rem;
    transition: all 0.3s;
}

#themeTogglerButton-light {
    color: #DD58D6;
    background-color: #fff;
}

#themeTogglerButton-dark {
    color: #fff;
    background-color: #191825;
}

/* Navbar styling */

/* Search Bar styling */

.searchInput,
.searchButton {
    font-size: 1.5rem;
    border-radius: 1rem;
    background-color: #E9E8E8;
}

.searchInput:focus {
    background-color: #E9E8E8;
}

.searchButton,
.searchButton:hover,
.searchButton:active {
    font-size: 1.5rem;
    border-radius: 1rem;
    background-color: #E9E8E8;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #DD58D6;
}

.searchInput {
    border-right: none;
    color: #191825;
}

/* Search Bar styling */

/* Image Box Styling */

.autorDownloadContainer {
    position: absolute;
    background-color: #19182535;
    width: 100%;
    height: 100%;
    color: #F5F5F5;
    bottom: 0;
}

.authorContainer {
    padding: 1rem;
}

.authorContainer > p {
    margin-bottom: 0;
    font-size: 1.4rem;
}

/* Image Box Styling */

/* General styling */

.imagesContainer {
    overflow: hidden;
}

.imageBox {
    width: 95%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
}


@media (min-width: 1000px)  {
    nav,
    .searchContainer,
    .imagesContainer {
        margin-right: auto;
        margin-left: auto;
    }

    img {
        height: 100%;
        width: 100%;
    }

    .authorContainer {
        padding: 1rem;
    }
    
    .authorContainer > p {
        margin-bottom: 0;
        font-size: 1.4rem;
    }
}

/* General styling */